<template>
  <div class="flex flex-col h-full text-base md:text-3xl">
    <div class="md:py-6">
      [{{ language }}]
    </div>
    <div>
      <ul class="text-left text-base md:text-xl xl:text-2xl">
        <li class="pb-2 pt-1 md:py-4" v-for="(item, index) in listOfProjects" :key=item.title>
          [{{ index + 1}}] {{ item.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import projectData from '../assets/text/project_data.json';
export default {
  name: 'ProejctsOverview',
  data: function() {
    return {
      languageData: null,
      language: null,
      overview: null,
      listOfProjects: null,
    };
  },
  created: function(event) {
    this.$store.commit('resetTechnologies');
    const currentLanguage = this.$router.currentRoute._value.params.lang;

    this.languageData = projectData[currentLanguage];
    this.language = currentLanguage.toUpperCase();
    this.overview = this.languageData.overview;
    this.listOfProjects = this.languageData['projects'];
    this.$store.commit('setProjectLanguage', currentLanguage);
    this.$store.commit('setNumberOfProjects', this.listOfProjects.length);
    this.$store.commit('setProjectsByLanguage', this.listOfProjects);
    this.$store.commit('setSwitchPostion', {posNumber: 2, url: this.$router.currentRoute._value.fullPath});
  },
};
</script>
