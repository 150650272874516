<template>
  <div class="flex flex-col h-full">
    <div class="flex flex-col w-full h-full">
      <div class="flex w-full justify-center">
        <div class="glitch text-2xl sm:text-5xl pb-4" data-text="Experience">
          Experience
        </div>
      </div>
      <div class="flex w-full scrollable-tv-text-container">
        <div class="w-full scrollable-tv-text-none text-base md:text-xl lg:text-2xl">
          <div class="text-center mb-4 sm:mb-0">
            <div>10/2015 – today [Endava]</div>
            <div>Senior Development Consultant</div>
          </div>
          <br class="hidden sm:block">
          <div class="text-center mb-4 sm:mb-0">
            <div>2015 – 9/2015 [Geneko]</div>
            <div>Software Developer</div>
          </div>
          <br class="hidden sm:block">
          <div class="text-center mb-4 sm:mb-0">
            <div>2012 – 2014 [TES]</div>
            <div>Software Design Engineer</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Experience',
  props: {
    title: String,
  },
  created: function(event) {
    this.$store.commit('setSwitchPostion', {posNumber: 1});
  },
};
</script>
